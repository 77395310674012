import React from "react";
import styled from "styled-components";
import Image, { FixedObject } from "gatsby-image";

import { SiteSiteMetadataAuthor } from "graphql-types";
import { rhythm } from "../../utils/typography";
import { yearsSince } from "../../utils/timeSince";
import { device } from "../../styles/constants";
import { useAvatar } from "../../hooks";

interface AboutProps {
  author: SiteSiteMetadataAuthor;
}

const About: React.FunctionComponent<AboutProps> = ({
  author,
}): React.ReactElement => {
  const avatar = useAvatar({ width: 200, height: 200 });

  return (
    <Root>
      <Avatar fixed={avatar.childImageSharp.fixed as FixedObject} />

      <Description>
        <h4>Hey there mate!</h4>
        <p>
          My name is {author.name}. I have a real passion for helping 
          organizations, groups and teams grow.  Finding practical ways to 
          implement Agile methodologies and remove waste is some of the most 
          rewarding work I have done.
        </p>

        <p>
          I contribute to the organizational growth by fostering collaboration 
          and empowering groups to be self deterministic.  I believe when 
          teams and people are given the space to do what they do best with 
          safety and support they will always impress and surpass expectations.
        </p>

        <p>
          When not working, I love to be outdoors with my family and dogs,
          learn about new technology, languages and cook (or eat) amazing 
          dinners. I believe a key to personal growth is through understanding 
          of our own biases and honest introspection.
        </p>

        <p>
          Do you want to find out more about me? Please shoot me an reach out 
          to me using any of the links below.
        </p>
      </Description>
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-gap: ${rhythm(2)};
  margin-top: ${rhythm(0.5)};

  @media ${device.tablet} {
    grid-template-columns: minmax(20%, 200px) 70%;
  }
`;

const Avatar = styled(Image)`
  align-self: center;

  border-radius: 50%;
  width: 150px;
  height: 150px;
  justify-self: center;
  align-self: flex-start;

  @media ${device.tablet} {
    width: auto;
    height: auto;
    justify-self: start;
  }
`;

const Description = styled.section`
  h4 {
    margin-top: ${rhythm(0.5)};
  }
`;

export default About;
